import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "../../component/header";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  storeMenuButton,
  storeMenuItems,
  storeStockList,
} from "../../services/tableQRServices";
import _, { get } from "lodash";
import MenuItems from "./MenuItems";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  setIsGST,
  setIsGSTInclusive,
  setIsServiceCharge,
  setTaxList,
  setTaxValue,
} from "../../redux/cartSlice";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderMenu from "../../component/headermenu";
import {
  getProductByIds,
  getProducts,
  getProductsByCategory,
} from "../../services/supabase/product";
import MenuDetail from "./MenuDetail";
import { CustomizeModal } from "./CustomizeModal";
import {
  getCategories,
  getCategoryByName,
} from "../../services/supabase/category";
import { getOutletByName } from "../../services/supabase/outlet";
import { getUpsellingByTargetId } from "../../services/supabase/upselling";
import UpsellingModal from "../../component/Upselling/UpsellingModal";
import getBrandImage from "../../utils/getBrandImage";
import getUpsellingItems from "../../utils/getUpsellingitems";
import { STORAGEKEY } from "../../constants";
import useSessionTimeout from "../../hooks/useSessionTimeout";
import { current } from "@reduxjs/toolkit";
import useCategory from "../../hooks/useCategory";
import useBrandConfig from "../../hooks/useBrandConfig";
import usePromotion from "../../hooks/usePromotion";
import { setOpenConfetti, setOpenLottieAddCart } from "../../redux/appSlice";
import Skeleton from "react-loading-skeleton";
import MenuItemPlaceholder from "../../component/MenuItemPlaceholder/MenuItemPlaceholder";

function Category() {
  const { posStoreStockList, posMenuButton, posFullItemList } = useSelector(
    (state) => state.pos
  );
  const { state } = useLocation();
  const { config } = useBrandConfig();
  const { handleConvertPromotionItem } = usePromotion();

  const [isBottomOpen, setIsBottomOpen] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [searchedMenuItems, setSearchedMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(state?.category);
  const [subCategory, setSubCategory] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [originalPOSCategories, setOriginalPOSCategories] = useState([]);

  const [selectedItem, setSelectedMenuItem] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showModalData, setShowModalData] = useState([]);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showUpsellingModal, setShowUpsellingModal] = useState(false);
  const [upsellingItems, setUpsellingItems] = useState([]);
  const [supaProducts, setSupaProducts] = useState({});

  // get the number of items in the cart
  const cartItems = useSelector((state) => state.cart.cart);
  // get items from the cart and then read the qty of each item
  // sum the items to get cart items count each time an item is added to the cart
  const cartItemsCount = cartItems.reduce((acc, item) => acc + item.qty, 0);
  const [allShowCategories, setAllShowCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const redeemableList = useSelector((state) => state.app.redeemableList);

  const sessionTimeout = useSessionTimeout();
  const navigate = useNavigate();

  const { checkingEmptyCategory } = useCategory();

  // fetch products from supabase

  const fetchSupabaseProducts = async (brand) => {
    setIsLoading(true);
    const resp = await getProducts(brand);
    if (resp?.data?.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp?.data?.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks?.map((item) => item.pos_item_no);
      const hashedProducts = resp?.data?.reduce((result, item) => {
        result[item.pos_item_no] = {
          name: item.name,
          dummyPrice: item.dummy_price,
          isTopPick: item.is_top_pick !== null ? true : false,
          image_url: item.image_url,
        };
        return result;
      }, {});
      setSupaProducts(hashedProducts);
      return topPicksIds;
    }
  };

  const fetchSupabaseProductsOriginal = async (brand) => {
    setIsLoading(true);
    const resp = await getProducts(brand);
    if (resp?.data?.length !== 0) {
      return resp?.data;
    }
  };

  const fetchSupabaseCategoriesOriginal = async () => {
    setIsLoading(true);

    const resp = await getCategories(process.env.REACT_APP_MODE);
    const respMainCategory = await storeMenuButton();

    setOriginalCategories(resp.data);
    setOriginalPOSCategories(respMainCategory?.data?.data?.[0]?.output);
  };

  // category image
  const fetchSupabaseCategories = async () => {
    const filterCategory = originalCategories?.filter(
      (record) => record?.is_hidden !== true && record?.is_hidden !== "Y"
    );
    const resultParse = JSON.parse(posStoreStockList);

    let formatAllCategories = [];

    originalPOSCategories
      ?.find((record) => record?.root_category_code === "MAIN")
      ?.category?.filter(
        (category) =>
          category.start_time <= moment().format("HH:mm:ss") &&
          category.end_time > moment().format("HH:mm:ss")
      )
      ?.forEach((record) => {
        const findPOSCategory = resultParse?.find(
          (cat) => cat?.item_category === record?.category_code
        );
        const findSupabaseCategory = filterCategory?.find(
          (cat) => cat?.name === record?.category_code
        );
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record?.category_code
        );

        if (
          findSupabaseCategory &&
          findPOSCategory &&
          findPOSCategory?.is_emenu_disable !== "Y" &&
          findPOSCategory?.is_soldout !== "Y"
        ) {
          formatAllCategories.push(findCategory);
        } else if (!findPOSCategory && findSupabaseCategory) {
          formatAllCategories.push(findCategory);
        }
      });

    formatAllCategories = formatAllCategories.sort(
      (a, b) => a?.sequence - b?.sequence
    );

    const findSubCategory = originalPOSCategories?.find(
      (record) => record?.root_category_code === currentCategory
    );

    if (findSubCategory && findSubCategory?.category) {
      const formatSubCategory = [];

      findSubCategory?.category?.forEach((record) => {
        if (
          filterCategory?.find((cat) => cat?.name === record?.category_code)
        ) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === record?.category_code
          );
          formatSubCategory.push(findCategory);
        }
      });

      const formatCategory = await checkingEmptyCategory(
        filterCategory,
        formatSubCategory
          ?.sort((a, b) => a?.sequence - b?.sequence)
          ?.map((record) => record?.name)
      );
      const finalCategory = formatCategory?.filter((record) => record);

      setSubCategory(finalCategory);
      setCurrentCategory(finalCategory?.[0]);
    } else if (
      !findSubCategory?.category &&
      formatAllCategories?.find(
        (record) => record?.name === findSubCategory?.root_category_code
      )
    ) {
      setSubCategory([]);
    }

    const formatAllSubCategory = [];

    formatAllCategories
      ?.map((record) => record?.name)
      .forEach((cat) => {
        const findSubCategory = originalPOSCategories?.find(
          (record) => record?.root_category_code === cat
        );

        if (
          findSubCategory &&
          !findSubCategory?.category &&
          findSubCategory?.items
        ) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === findSubCategory?.root_category_code
          );

          if (findCategory) {
            formatAllSubCategory.push(findCategory);
          }
        }
      });

    const formatAllCategory = await checkingEmptyCategory(
      filterCategory,
      formatAllSubCategory?.map((record) => record?.name)
    );

    const filterAllCategories = formatAllCategories?.filter(
      (record) =>
        !formatAllSubCategory
          ?.map((record) => record?.name)
          ?.includes(record?.name)
    );
    const filterFormatCategories = [];

    formatAllCategory
      ?.filter((record) => record)
      ?.forEach((record) => {
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record
        );

        if (findCategory) {
          filterFormatCategories.push(findCategory);
        }
      });

    setMainCategories(
      formatAllCategories?.sort((a, b) => a?.sequence - b?.sequence)
    );
    setAllShowCategories(
      [...filterFormatCategories, ...filterAllCategories]
        ?.sort((a, b) => a?.sequence - b?.sequence)
        ?.map((record) => record?.name)
    );
    setAllCategories(filterCategory?.sort((a, b) => a?.sequence - b?.sequence));
  };

  useEffect(() => {
    fetchSupabaseCategoriesOriginal();
  }, []);

  useEffect(() => {
    fetchSupabaseCategories();
  }, [currentCategory, originalCategories, originalPOSCategories]);

  useEffect(() => {
    setCurrentCategory(state.category);
  }, [state.category]);

  // function to get the category image based on name
  const getCategoryImage = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    return category?.image_url;
  };
  // function to get the category short name based on name
  const getCategoryShortName = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    return category?.short_name;
  };

  const subTotal = useSelector((state) => state.cart.total);
  const dispatcher = useDispatch();

  // useEffect(() => {
  //   itemsListAndCategory();
  // }, [currentCategory]);
  useEffect(() => {
    if (allCategories.length !== 0) {
      itemsListAndCategory();
    }
  }, [allCategories, currentCategory]);

  const itemsListAndCategory = async () => {
    setIsLoading(true);

    const resultParse = JSON.parse(posStoreStockList);

    let resultItemsParsed = resultParse.filter(
      (category) => category.avl_type === "I"
    );

    const categoryNames = allCategories.map((item) => item.name);
    await menuButtonFunction(categoryNames, resultItemsParsed, resultParse);

    setIsLoading(false);
  };

  const menuButtonFunction = async (
    categoryCodes,
    resultItemsParsed,
    resultParse
  ) => {
    let newCategoryCodes = categoryCodes;

    const categoryMenuButton = posMenuButton;

    let categoryMainMenu = categoryMenuButton.filter(
      (menuItem) => menuItem.category && menuItem.category.length > 0
      // (menuItem) => menuItem.root_category_code === "MAIN"
      // filter menuItem.items length > 0
      // (menuItem) => menuItem.items.length > 0
    );
    let categoryMainMenuButton = [];
    for (const catItems of categoryMainMenu) {
      categoryMainMenuButton.push(...catItems.category);
    }
    if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
      let categoriesIndex = categoryMainMenuButton;
      // categoryMainMenuButton = categoryMainMenuButton[0];
      // let categoriesIndex = categoryMainMenuButton.category;
      // ? Sort on sequences and filter on start and end time
      categoriesIndex = categoriesIndex
        .sort((a, b) => a.course_seq - b.course_seq)
        .filter(
          (category) =>
            category.start_time <= moment().format("HH:mm:ss") &&
            category.end_time > moment().format("HH:mm:ss")
        );

      const categoregoryFromApi = categoryMenuButton.map((category) => {
        return category.root_category_code;
      });
      newCategoryCodes = _.intersection(newCategoryCodes, categoregoryFromApi);
      // for (const category of categoriesIndex) {
      //   categoryCodes.push(category.category_code);
      // }
      let menuItemsArray = [];
      for (const category of newCategoryCodes) {
        const catItem = categoryMenuButton.filter(
          (catItems) => catItems.root_category_code === category
        );
        if (
          catItem &&
          catItem.length > 0 &&
          catItem[0].items &&
          catItem[0].items.length > 0
        ) {
          menuItemsArray.push(...catItem[0].items);
        }
      }
      // ? Intersect items from stock and button api
      let intersectCategory = _.intersectionBy(
        resultItemsParsed,
        menuItemsArray,
        (obj) => obj.item_category || obj.item_no
      );
      // ? Intersect items from with intersected items and button api items
      intersectCategory = Object.values(
        _.merge(
          _.keyBy(intersectCategory, "item_category"),
          _.keyBy(menuItemsArray, "item_no")
        )
      );
      // temp disable for demo
      // .filter(
      //   (item) =>
      //     item.is_emenu_disable === "N" &&
      //     item.start_time <= moment().format("HH:mm:ss") &&
      //     item.end_time > moment().format("HH:mm:ss")
      // );

      setMenuCategories(mainCategories?.map((record) => record?.name));
      await menuItemsFunction(intersectCategory, newCategoryCodes, resultParse);
    }
  };

  const menuItemsFunction = async (
    intersectCategory,
    categoryCodes,
    resultParse
  ) => {
    const newResultParse = resultParse?.filter(
      (record) => record?.is_emenu_disable !== "Y"
    );
    const result = posFullItemList;
    const newResult = result.filter((item) => {
      const findItem = resultParse?.find(
        (record) => record?.item_no === item?.item_no
      );

      return findItem?.is_emenu_disable !== "Y";
    });

    const filterItemMaster = newResult.filter(
      (item) =>
        item.itemmaster_menutypedtls && item.itemmaster_menutypedtls.length > 0
    );

    const stockedCheckedItem = [];
    if (filterItemMaster && filterItemMaster.length > 0) {
      for (const itemMaster of filterItemMaster) {
        let itemmaster_menutypedtls = [];
        for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
          const modifierQty = newResultParse.filter(
            (itemCategory) => itemCategory.item_no === typeDtls.citem_no
          );
          const findItem = resultParse?.find(
            (record) => record?.item_category === typeDtls.citem_no
          );
          const checkingDisabledMenu =
            findItem?.is_emenu_disable === "N" && findItem?.is_soldout === "N";

          if (checkingDisabledMenu) {
            if (modifierQty && modifierQty.length > 0) {
              itemmaster_menutypedtls.push({
                ...typeDtls,
                is_soldout: modifierQty[0].is_soldout,
                is_emenu_disable: modifierQty[0].is_emenu_disable,
                is_avl_limit_check: modifierQty[0].is_avl_limit_check,
                bal_qty: modifierQty[0].bal_qty,
              });
            } else {
              itemmaster_menutypedtls.push({ ...typeDtls });
            }
          } else if (!findItem) {
            itemmaster_menutypedtls.push({ ...typeDtls });
          }
        }

        stockedCheckedItem.push({ ...itemMaster, itemmaster_menutypedtls });
      }
    }

    const filterNoItemMaster = newResult.filter(
      (item) =>
        !item.itemmaster_menutypedtls ||
        item.itemmaster_menutypedtls.length <= 0
    );

    let intersectItems = _.intersectionBy(
      intersectCategory,
      [...filterNoItemMaster, ...stockedCheckedItem],
      (obj) => obj.item_category || obj.item_no
    ).filter((item) => item.is_active); // temp disable

    intersectItems = Object.values(
      _.merge(
        _.keyBy(intersectCategory, "item_category"),
        _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
      )
    )
      .filter(
        (item) => item.is_active && categoryCodes.includes(item.category_code)
      )
      .filter((item) => item.is_emenu_disable !== "Y");

    const brand = process.env.REACT_APP_MODE;
    const topPicks = await fetchSupabaseProducts(brand);
    const productByCategory = await getProductsByCategory(
      brand,
      currentCategory
    );

    // if menu item_no is in topPicksId, the then set is_top_pick to true
    intersectItems = intersectItems.map((item) => {
      if (topPicks.includes(item.item_no)) {
        return { ...item, is_top_pick: true };
      }
      return { ...item };
    });

    const sortAlphabetically = intersectItems.sort((a, b) =>
      a.item_desc.localeCompare(b.item_desc)
    );
    const categoryMenus = sortAlphabetically.filter(
      (item) => item.category_code === currentCategory
    );

    const newCategoryMenus = [];

    categoryMenus?.forEach((record) => {
      const findItem = productByCategory?.data?.find(
        (product) => product?.name === record?.item_name
      );

      let newItem = {
        ...record,
      };

      if (findItem) {
        newItem = {
          ...newItem,
          dummy_price: findItem?.dummy_price,
          supabase_item_desc: findItem?.description,
        };
      }

      newCategoryMenus.push(newItem);
    });

    const sortByCategory = newCategoryMenus.filter(
      (item) =>
        item.start_time <= moment().format("HH:mm:ss") &&
        item.end_time > moment().format("HH:mm:ss")
    );

    console.log("sortByCategory", sortByCategory);

    setMenuItems(sortByCategory);
    setIsLoading(false);
  };

  let menuItem, menuDish;
  menuItem = allShowCategories;
  menuDish = searchedMenuItems && showSearchBar ? searchedMenuItems : menuItems;

  const getMenuTypeGrouped = useCallback((data) => {
    return (get(data, "itemmaster_menutype_grpdtls", []) || [])
      .slice()
      .sort((a, b) => a.item_menutype_grpdtls - b.item_menutype_grpdtls)
      .map((menuTypeGroup, i) => menuTypeGroup);
  }, []);

  const selectedDineOption = localStorage.getItem(STORAGEKEY.DINING_MODE);

  const isDineIn = useMemo(
    () => selectedDineOption === "dinein",
    [selectedDineOption]
  );

  const placeOrder = async (dishDetail) => {
    if (
      dishDetail.itemmaster_menutype_grpdtls === "" // temp fix
      // dishDetail.itemmaster_menutype_grpdtls === "" ||
      // menuTypeDtls?.every((record) => record?.length === 1)
    ) {
      let addOnValues = [];
      const menuTypeDtls = getMenuTypeGrouped(dishDetail)?.map(
        (menuTypeGroup) =>
          dishDetail?.itemmaster_menutypedtls.filter(
            (customItems) =>
              customItems.modifier_name === menuTypeGroup.modifier_name
          )
      );

      menuTypeDtls?.forEach((childMenuTypeDtls) => {
        childMenuTypeDtls?.forEach((addOnData) => {
          addOnValues.push({
            modifier_qty: 1,
            item_no: addOnData.citem_no,
            modifier_name: addOnData.modifier_name,
            citem_name: addOnData.citem_name,
            price_dtls: isDineIn
              ? addOnData.price_dtls[0].dine_in_price
              : addOnData.price_dtls[0].takeaway_price,
            sku_no: addOnData.citem_no.replace("PRD", "SKU"),
            uom: addOnData.uom,
          });
        });
      });

      addOnValues.reverse();

      const itemInCart = cartItems.find(
        (item) =>
          item.id === dishDetail.item_no &&
          item.addOnValues.length === 0 &&
          _.isEqual(_.map(item.addOnValues, "item_no"), _.map([], "item_no")) &&
          !item.item.isFree
      );

      dispatcher(
        addToCart(
          handleConvertPromotionItem({
            uuid: itemInCart?.uuid,
            id: dishDetail.item_no,
            addOnValues,
            item: dishDetail,
            qty: 1,
            price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
          })
        )
      );
      if (config?.enableLottie) {
        dispatcher(setOpenLottieAddCart(true));
      }
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
        dishDetail.category_code,
        dishDetail.item_category
      );
      if (hasUpsellingItems) {
        setUpsellingItems(upsellItems);
        setShowUpsellingModal(true);
      }
    } else {
      const customDetails = {
        item: dishDetail,
      };

      if (config?.usingCustomizePage) {
        navigate("/customize-dish", {
          state: customDetails,
        });
      } else {
        setShowModalData(customDetails);
        setShowCustomizeModal(true);
      }
    }
  };

  const toggleBottom = () => {
    if (
      localStorage.getItem("selectiDineOption") === "dinein" ||
      localStorage.getItem("selectiDineOption") === "takeaway"
    ) {
      setIsBottomOpen((prev) => !prev);
    }
  };

  const openDiningOption = () => {
    setIsBottomOpen(true);
  };

  const selectDining = (selectionOption) => {
    localStorage.setItem("selectiDineOption", selectionOption);
    // set current cart to dine in
    setIsBottomOpen(false);
  };
  const searchFunction = (values) => {
    if (values && values.length > 0) {
      setSearchedMenuItems(
        menuItems.filter(
          (item) =>
            item.item_name.includes(values) ||
            item.item_name.toLowerCase().includes(values.toLowerCase()) ||
            item.item_name.toUpperCase().includes(values.toUpperCase())
        )
      );
    } else {
      // itemsListAndCategory();
      setSearchedMenuItems(menuItems);
    }
  };
  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
  };

  const [initialized, setInitialized] = useState(false);
  const menuRef = useRef();

  let pendingAction;
  const calculateScrollFn = () => {
    if (pendingAction) {
      clearTimeout(pendingAction);
      pendingAction = false;
    }
    pendingAction = setTimeout(() => {
      const activeMenuLi = document.querySelector(".spyItemClass.active");
      if (!activeMenuLi) {
        return;
      }
      const menuContainer = activeMenuLi.parentNode;

      const elementRect = activeMenuLi.getBoundingClientRect();
      const containerRect = menuContainer.getBoundingClientRect();

      let scrollGap;
      if (elementRect.top < containerRect.top) {
        scrollGap = elementRect.top - containerRect.top;
      }
      if (elementRect.bottom > containerRect.bottom) {
        scrollGap = elementRect.bottom - containerRect.bottom;
      }
      if (scrollGap) {
        menuContainer.scrollBy(0, scrollGap);
      }
    }, 50);
  };

  function initScrollListener() {
    if (initialized) {
      return;
    }
    if (!menuRef || !menuRef.current) {
      return;
    }
    menuRef.current.removeEventListener("scroll", calculateScrollFn);
    menuRef.current.addEventListener("scroll", calculateScrollFn);
    setInitialized(true);
  }

  const toggleDetailModal = (data = {}) => {
    setSelectedMenuItem({
      ...data,
      supaProduct: supaProducts[data.item_category],
    });
    if (showDetailModal) {
      setShowDetailModal(false);
    } else {
      setShowDetailModal(true);
    }
  };

  function insertLineBreak(category) {
    if (category?.includes("(")) {
      return category?.replace("(", "\n(");
    } else {
      return category;
    }
  }
  const handleCustomModalSuccessSubmit = async (submittedItem) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      submittedItem.category_code,
      submittedItem.item_category
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  const handleTaxLogicFromPOS = () => {
    dispatcher(setTaxList(menuDish));
  };

  useEffect(() => {
    if (menuDish?.length > 0) {
      handleTaxLogicFromPOS();
    }
  }, [menuDish]);

  useEffect(() => {
    if (config) {
      dispatcher(
        setIsServiceCharge(config?.enableServiceCharge ? true : false)
      );
    }
  }, [config]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header
            title="home"
            showSearchBar={showSearchBar}
            handleSearchClick={handleSearchClick}
            searchFunction={searchFunction}
            openDiningOption={openDiningOption}
          />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div
            className="main-div home-div position-absolute w-100"
            ref={menuRef}>
            <HeaderMenu menuItem={menuItem} subCategory={subCategory} />
            <div
              className="top-border d-flex flex-column position-relative"
              style={{
                paddingTop: 180,
                minHeight: "100vh",
              }}>
              {!initialized && initScrollListener()}
              <div>
                {menuItem && menuItem.length > 0 ? (
                  <img
                    className={`category-header-image ${
                      !getCategoryImage(currentCategory) ? "image-contain" : ""
                    }`}
                    src={
                      getCategoryImage(currentCategory) ||
                      `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`
                    }
                    alt="header"
                  />
                ) : (
                  <Skeleton height={450} />
                )}
              </div>
              <div
                id="menuitems"
                className="menuitems category-menuitems w-100 pt-2 d-flex flex-column">
                <div className="category-header-title mt-1 w-100 break-parenthesis">
                  <h1
                    style={{
                      marginTop: 48,
                    }}>
                    {insertLineBreak(currentCategory)}
                  </h1>
                </div>
                <div className="menuitems category-menuitems w-100 pt-2 category-cards-container mt-2 px-2">
                  {isLoading ? (
                    Array.from({ length: 6 }).map((_, index) => (
                      <Fragment key={index}>
                        <MenuItemPlaceholder />
                      </Fragment>
                    ))
                  ) : menuDish.length > 0 ? (
                    <>
                      {menuDish.map((menuDishes, index) => {
                        return (
                          <MenuItems
                            key={`menuItem-${index}`}
                            menuDishes={menuDishes}
                            placeOrder={placeOrder}
                            onDetailOpen={toggleDetailModal}
                            showModalData={showModalData}
                            supaProduct={
                              supaProducts?.[menuDishes?.item_category]
                            }
                            supaProducts={supaProducts}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        gridColumn: "1/-1",
                      }}>
                      <img
                        src={`/assets/commonImages/${config?.search}.png`}
                        alt=""
                        className="mt-5"
                      />
                      <div className="d-grid">
                        <label className="no-result-text black-text">
                          No Result
                        </label>
                        <label className="no-result-desc text-black">
                          Sorry we couldn't find any result, try another
                          keyword.
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDetailModal ? (
        <MenuDetail
          show={showDetailModal}
          onHide={toggleDetailModal}
          data={selectedItem}
          placeOrder={placeOrder}
        />
      ) : null}
      {showCustomizeModal ? (
        <CustomizeModal
          showModal={showCustomizeModal}
          setShowModal={setShowCustomizeModal}
          showModalData={showModalData}
          onSuccessSubmit={handleCustomModalSuccessSubmit}
        />
      ) : null}
      {showUpsellingModal ? (
        <UpsellingModal
          show={showUpsellingModal}
          handleClose={() => setShowUpsellingModal(false)}
          recommendationItems={upsellingItems}
        />
      ) : null}
    </>
  );
}
export default Category;
